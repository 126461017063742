<template>
    <div class="sup_content sup_coupon">
        <!--- Create New Role --->
        <div class="sup_page_heading">
            <h2>Cookie Management</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Coupon'" @click="couponModals.addCoupon = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Coupon list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allCouponData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allCouponData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Coupon data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allCouponData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allCouponData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allCouponData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllCoupon(allCouponData.activityStatus, 'couponStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allCouponData.search" placeholder="Search Coupon" @keyup.enter="fetchAllCoupon(allCouponData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allCouponData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allCouponData.dateRange[1] != null ? fetchAllCoupon(allCouponData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allCouponData.search !== null || allCouponData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllCoupon(allCouponData.activityStatus, 'resetFilters')"/>
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType" @click="exportXL('excel')"></SplitButton>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Coupon item found...
                </template>
                <Column field="couponName" header="Name" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{slotProps.data.couponName}}</strong>
                    </template>
                </Column>

                <Column field="couponCode" header="Code" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.couponCode}}
                    </template>
                </Column>

                <Column field="monthlyDiscount" header="Discount">
                    <template #body="slotProps">
                        {{slotProps.data.couponType === 'percentage' ? slotProps.data.monthlyDiscount + '%' : '$' + slotProps.data.monthlyDiscount}}
                    </template>
                </Column>

                <Column field="createdAt" header="Validity">
                    <template #body="slotProps">
                    {{slotProps.data.startDate + " - " + slotProps.data.endDate}}
                    </template>
                </Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editCoupon(slotProps.data)" v-tooltip="'Edit Coupon'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Coupon'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Coupon'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Coupon list End --->
        </div>
        <!--- Add Coupon Modal Start --->
        <Dialog header="Add New Coupon" v-model:visible="couponModals.addCoupon" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_coupon_add" @submit.prevent="createCoupon()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="coupon_name">Coupon Name*</label>
                        <InputText v-model="addEditCoupon.name"  id="coupon_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.nameEmpty">Coupon Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="coupon_type">Coupon Type*</label>
                        <Dropdown v-model="addEditCoupon.type" :options="couponType" id="coupon_type" optionLabel="name" optionValue="value" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.typeEmpty">Coupon Type is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="key_name">Coupon Code*</label>
                        <InputText v-model="addEditCoupon.code" id="key_name" type="text" placeholder="Eg: CODE" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.codeEmpty">Coupon Code is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_discount">Monthly Discount*</label>
                        <InputText v-model="addEditCoupon.discount" id="key_discount" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.discountEmpty">Coupon Discount is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="start_dateformat">Start Date*</label>
                        <Calendar id="start_dateformat" v-model="addEditCoupon.startDate" dateFormat="dd-mm-yy" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.startDateEmpty">Start date not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_value">Edn Date*</label>
                        <Calendar id="end_dateformat" v-model="addEditCoupon.endDate" dateFormat="dd-mm-yy" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.endDateEmpty">End Date is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allCouponData.addStatus === 200 ? 'success' : 'error']" v-if="allCouponData.addStatus !== ''">{{allCouponData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Coupon Modal End --->
        <!--- Edit Coupon Modal Start --->
        <Dialog header="Edit Coupon" v-model:visible="couponModals.editCoupon" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_coupon_edit" @submit.prevent="updateCoupon(couponModals.editData.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="coupon_name">Coupon Name*</label>
                        <InputText v-model="addEditCoupon.name"  id="coupon_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.nameEmpty">Coupon Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        {{addEditCoupon.type}}
                        <label for="coupon_type">Coupon Type*</label>
                        <Dropdown v-model="addEditCoupon.type" :options="couponType" id="coupon_type" optionLabel="name" optionValue="value" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.typeEmpty">Coupon Type is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="key_name">Coupon Code*</label>
                        <InputText v-model="addEditCoupon.code" id="key_name" type="text" placeholder="Eg: CODE" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.codeEmpty">Coupon Code is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_discount">Monthly Discount*</label>
                        <InputText v-model="addEditCoupon.discount" id="key_discount" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.discountEmpty">Coupon Discount is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="start_dateformat">Start Date*</label>
                        <Calendar id="start_dateformat" v-model="addEditCoupon.startDate" dateFormat="dd-mm-yy" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.startDateEmpty">Start date not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_value">Edn Date*</label>
                        <Calendar id="end_dateformat" v-model="addEditCoupon.endDate" dateFormat="dd-mm-yy" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCouponError.endDateEmpty">End Date is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allCouponData.editStatus === 200 ? 'success' : 'error']" v-if="allCouponData.editStatus !== ''">{{allCouponData.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Coupon Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreCoupon(confirmationModal.modaldata.data, confirmationModal.modaldata.type), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
        <Toast position="top-right" />
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { allCouponList, allCouponExport } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate, capitalizeCase } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');

export default {
    name: "CouponManagement",
    setup() {
        //Store call
        let store = useStore();

        //Toast Message
        const toast = useToast();

        //Fetch and filtring data initial state
        const allCouponData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: ''
        });

        //Coupon modals handling state
        const couponModals = reactive({
            addCoupon: false,
            editCoupon: false,
            editData: false
        });

        //Add/edit coupon input value storing state
        const addEditCoupon = reactive({
            name: '',
            type: '',
            code: '',
            startDate: '',
            endDate: '',
            discount: ''
        });

        //Add/edit coupon form validation state
        const addEditCouponError = reactive({
            nameEmpty: false,
            typeEmpty: false,
            codeEmpty: false,
            startDateEmpty: false,
            endDateEmpty: false,
            discountEmpty: false
        });

        //Coupon type option value
        const couponType = reactive([
            {name: "Percentage", value: "Percentage"},
            {name: "Fixed", value: "Fixed"},
        ]);

        //Active-deactive confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //Export type object and method inside
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        /**
         * On component get mounted require functions call
         */
        onBeforeMount(async () => {
            fetchAllCoupon('active');
        });

        //Fetch All Coupon items
        const fetchAllCoupon = async(status, activity) => {
            let date = null;
            if(activity === 'couponStatus'){
                allCouponData.activityStatus = status === 'active' ? 'de-active' : 'active' ;
            }

            if(activity === 'resetFilters'){
                allCouponData.search = null;
                allCouponData.dateRange = null;
            }

            if (activity === 'dateRange') {
                date = formatDate(allCouponData.dateRange[0]) + ' ' + formatDate(allCouponData.dateRange[1]);
            }

            try{
                allCouponData.loading = !allCouponData.loading;
                const response = await axios.get(allCouponList, {
                    params: {
                        status: allCouponData.activityStatus,
                        search: allCouponData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                })
                allCouponData.data = response.data.data.coupon;
                allCouponData.total = response.data.data.total;
                allCouponData.loading = !allCouponData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Export Coupon list
        const exportXL = async (load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    if (allCouponData.dateRange !== null) {
                        date = formatDate(allCouponData.dateRange[0]) + ' ' + formatDate(allCouponData.dateRange[1]);
                    }
                    const response = await axios.get( allCouponExport, {
                        responseType: 'arraybuffer',
                        params: {
                            exe: "xlsx",
                            print: false,
                            status: allCouponData.activityStatus,
                            search: allCouponData.search,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        //Create New coupon
        const createCoupon = async() => {
            let validation = couponValidationForm();
            if(validation){
                try{
                    const response = await axios.post(allCouponList, {
                        couponName: addEditCoupon.name,
                        couponCode: addEditCoupon.code,
                        couponType: addEditCoupon.type,
                        monthlyDiscount: addEditCoupon.discount,
                        startDate: addEditCoupon.startDate,
                        endDate: addEditCoupon.endDate
                    },{
                        headers:  {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }
                    });
                    allCouponData.addStatus = response.data.status;
                    allCouponData.addMessage = response.data.data;
                    resetForm();
                    fetchAllCoupon();
                } catch(err){
                    console.log(err);
                }
            }
        }

        const editCoupon = (slotData) => {
            couponModals.editCoupon = true;
            couponModals.editData = slotData;
            addEditCoupon.name = slotData.couponName;
            addEditCoupon.type = capitalizeCase(slotData.couponType);
            addEditCoupon.code = slotData.couponCode;
            addEditCoupon.discount = slotData.monthlyDiscount;
            addEditCoupon.startDate = slotData.startDate;
            addEditCoupon.endDate = slotData.endDate;
        }

        //Update existing coupon
        const updateCoupon = async(uuid) => {
            let validation = couponValidationForm();
            if(validation){
                try{
                    const options = {
                        method: 'put',
                        url: allCouponList,
                        params: {
                            uuid: uuid
                        },
                        data: JSON.stringify({
                            couponName: addEditCoupon.name,
                            couponCode: addEditCoupon.code,
                            couponType: addEditCoupon.type,
                            monthlyDiscount: addEditCoupon.discount,
                            startDate: addEditCoupon.startDate,
                            endDate: addEditCoupon.endDate
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    let response = await axios(options);
                    allCouponData.editStatus = response.data.status;
                    allCouponData.editMessage = response.data.message;
                    fetchAllCoupon('active');
                } catch(err){
                    allCouponData.editStatus = err.response.data.status;
                    allCouponData.editMessage = err.response.data.message;
                }
            }
        }

        //Coupon form validation
        const couponValidationForm = () => {
            if (addEditCoupon.name === '') {
                addEditCouponError.nameEmpty = true;
            } else {
                addEditCouponError.nameEmpty = false;
            }

            if (addEditCoupon.type === '') {
                addEditCouponError.typeEmpty = true;
            } else {
                addEditCouponError.typeEmpty = false;
            }

            if (addEditCoupon.code === '') {
                addEditCouponError.codeEmpty = true;
            } else {
                addEditCouponError.codeEmpty = false;
            }

            if (addEditCoupon.startDate === '') {
                addEditCouponError.startDateEmpty = true;
            } else {
                addEditCouponError.startDateEmpty = false;
            }

            if (addEditCoupon.endDate === '') {
                addEditCouponError.endDateEmpty = true;
            } else {
                addEditCouponError.endDateEmpty = false;
            }

            if (addEditCoupon.discount === '') {
                addEditCouponError.discountEmpty = true;
            } else {
                addEditCouponError.discountEmpty = false;
            }

            if (!addEditCouponError.nameEmpty && !addEditCouponError.typeEmpty && !addEditCouponError.codeEmpty && !addEditCouponError.startDateEmpty && !addEditCouponError.endDateEmpty && !addEditCouponError.discountEmpty) {
                return true;
            } else {
                return false;
            }
        }

        const resetForm = () => {
            addEditCoupon.name = '';
            addEditCoupon.code = '';
            addEditCoupon.type = '';
            addEditCoupon.discount = '';
            addEditCoupon.startDate = '';
            addEditCoupon.endDate = '';
        }

        const close = () => {
            resetForm();
            allCouponData.addStatus = '';
            allCouponData.addMessage = '';
            allCouponData.editStatus = '';
            allCouponData.editMessage = '';
        }

        //Delete-restore Email type confirmation modal
        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                type: load
            }
        }
        
        //Delete-restore Email type HTTP call
        const deleteAndRestoreCoupon = async (slotData, methodType) => {
            try{
                const options = {
                    method: methodType === 'delete' ? 'delete' : 'patch',
                    url: allCouponList,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllCoupon('active');
            } catch(err){
                if(err.response.status === 400) {
                    toast.add({severity:'warn', summary: 'De-active Warning', detail: err.response.data.message, life: 5000});
                }
            }
        }

        return{
            exportType,
            allCouponData,
            couponModals,
            addEditCoupon,
            addEditCouponError,
            couponType,
            confirmationModal,
            fetchAllCoupon,
            usFormatDate,
            exportXL,
            createCoupon,
            close,
            confirmationModalfn,
            deleteAndRestoreCoupon,
            editCoupon,
            updateCoupon
        }
    }
}
</script>

<style>

</style>